angular.module('taric')
    .config(['$routeProvider', routeConfigurator])
    .config(['$translateProvider', translationConfigurator])
    .value('config', {
        apiHosts: {
            auth: '/auth',
            generic: '/genericapi',
            dtv: '/dtvapi',
        },
        applicationKey: '69BA425103444812BBF83C276C5ABC74',
        applicationSecret: '9B9A1B5B786546D58F05697B053DB5FA',
        currentApi: 'dtv',
        version: 2.4
    })
    .run(['$location', '$rootScope', 'config', 'identity', authenticateRoutes]);

function locationConfigurator($locationProvider) {
    //$locationProvider.html5Mode(true).hashprefix('');
}

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/test', {
            templateUrl: 'widgets/test.html'
        })
        .otherwise({
            redirectTo: '/sections'
        });
}

function toastConfig(toastrConfig) {
    _.extend(toastrConfig, {
        positionClass: 'toast-bottom-right',
        timeOut: 2500
    });
}

function translationConfigurator($translateProvider) {
    var defaultLanguage = 'en';
    var translations_en = require('./translations/en/_index');
    var translations_it = require('./translations/it/_index');
    var translations_nl = require('./translations/nl/_index');
    

    $translateProvider
        .translations('en', translations_en)
        .translations('it', translations_it)
        .translations('nl', translations_nl)
        .preferredLanguage(defaultLanguage)
        .fallbackLanguage(defaultLanguage)
        .useSanitizeValueStrategy(null);
}

function authenticateRoutes($location, $rootScope, config, identity) {
    $rootScope.$on("$locationChangeStart", function (event, next, current) {
        if (identity.isAuthenticated)
            return;

        var unauthenticatedRoutes = [
            '/account/login',
            '/account/forgot-password',
            '/account/request-password',
            '/account/reset-password',
        ];

        var locationToCheck = $location.path();

        if (unauthenticatedRoutes.indexOf(locationToCheck) > -1)
            return;

        var path = $location.path();
        if (path === '/account/logout' || path === '/' || path === '') {
            $location.url('/account/login');
        }
        else {
            $location.url('/account/login?redirect=' + $location.path());
        }
        event.preventDefault();
    });
}
